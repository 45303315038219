import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import makeCancellablePromise from 'make-cancellable-promise';
import { collection, getDoc, getDocs, doc, onSnapshot, query, where, limit } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';

import Loading from './../components/Loading';
import { auth, db } from './../helper/firebase';

const Login = React.lazy(() => import('./Login/Login')),
// const Login = React.lazy(() => {
//         return new Promise(resolve => {
//           setTimeout(() => resolve(import('./Login/Login')), 300000);
//         });
//       }),
      Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      signIn: '',
      dataUser: '',
      privilege: '',
      brand: '',
      logOutLoading: false,
      appConfig: ''
    }

    this.unsubscribe = '';
    this.cancellableSetting = '';
    this.cancellableUser = '';
    this.cancellableCheck = '';
    this.cancellableBrand = '';
    this.cancellableLogout = '';

    this.logOut = this.logOut.bind(this);
  }

  componentDidMount(){
    // console.log(process.env.REACT_APP_PUBLIC_SALTED);
    
    this.cancellableSetting = makeCancellablePromise(
      getDocs(
        query(
          collection(db, 'setting'), limit(1)
        )
      )
    );

    this.cancellableSetting.promise.then((settingSnapshot) => {
      const settingData = settingSnapshot.docs[0].data(),
            yearNow = new Date().getFullYear();

      this.setState({
        appConfig: {
          url: settingData.settingWeb,
          dateFormat: settingData.settingAdminDate,
          timeFormat: settingData.settingAdminTime,
          dateTimeFormat: settingData.settingAdminDate + ' ' + settingData.settingAdminTime,
          copy: settingData.settingCopyright === yearNow ? settingData.settingCopyright : settingData.settingCopyright + ' - ' + yearNow,
          desc: settingData.settingAdmin,
          safeMode: settingData.settingSafe,
        }
      });
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });

    onAuthStateChanged(auth, (user) => {
      // console.log(user);

      if(this.unsubscribe){
        this.unsubscribe();
      }

      if(user){
        this.cancellableUser = makeCancellablePromise(
          getDoc(
            doc(db, 'user', user.uid)
          )
        );

        this.cancellableUser.promise.then((userDoc) => {
          if(userDoc.exists()){
            const userData = userDoc.data();

            if(userData.userStatus && userData.userActive){
              this.cancellableCheck = makeCancellablePromise(
                getDocs(
                  query(
                    collection(db, 'user', user.uid, 'userPrivilege'), where('userPrivilegeApp', '==', 'indomak.co.id'), where('userPrivilegeAdmin', '==', true), limit(1)
                  )
                )
              );
          
              this.cancellableCheck.promise.then((checkSnapshot) => {
                if(checkSnapshot.size){
                  const docRef = doc(db, 'user', user.uid, 'userPrivilege', checkSnapshot.docs[0].id),
                        priData = checkSnapshot.docs[0].data();

                  if(priData.userPrivilegeBrand){
                    let listPri = [];

                    this.cancellableBrand = makeCancellablePromise(
                      getDoc(
                        priData.userPrivilegeBrand
                      )
                    );
            
                    this.cancellableBrand.promise.then((priDoc) => {
                      const brandData = priDoc.data();

                      brandData.brandPrivilege.forEach((value, index) => {
                        listPri.push({
                          parent: value.brandPrivilegeParent,
                          child: value.brandPrivilegeChild
                        });
                      });

                      this.setState({
                        privilege: listPri,
                        brand: {
                          name: brandData.brandName,
                          url: brandData.brandWeb,
                          guaranty: brandData.brandGuaranty,
                          doc: brandData.brandDocument,
                          finishing: brandData.brandFinishing,
                          cat: brandData.brandCatalogueImage,
                          subcat: brandData.brandSubcatalogue,
                          subbrand: brandData.brandSubbrand,
                          variant: brandData.brandVariant
                        }
                      });
                    }).catch((error) => {
                      console.error(error);
                
                      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
                      
                      this.logOut();
                    });
                  }
    
                  this.unsubscribe = onSnapshot(docRef, (doc) => {
                    if(doc.data().userPrivilegeAdmin){
                      this.setState({
                        signIn: true,
                        dataUser: {
                          uid: user.uid,
                          brand: priData.userPrivilegeBrand ? priData.userPrivilegeBrand : '',
                          email: userData.userEmail,
                          name: userData.userNameFirst + ' ' + userData.userNameLast,
                          nameFirst: userData.userNameFirst,
                          nameLast: userData.userNameLast
                        }
                      });
                    }else{
                      this.logOut();
                    }
                  }, (error) => {
                    console.error(error);
          
                    toast.error(() => (<>{error.code}<span>{error.message}</span></>));
                    
                    this.logOut();
                  });
                }else{
                  toast.error(() => (<>Opps...<span>Maaf, kamu bukan admin.</span></>));
    
                  this.logOut();
                }
              }).catch((error) => {
                console.error(error);
          
                toast.error(() => (<>{error.code}<span>{error.message}</span></>));
                
                this.logOut();
              });
            }else{
              toast.error(() => (<>Opps...<span>User not found.</span></>));
    
              this.logOut();
            }
          }else{
            toast.error(() => (<>Opps...<span>User not found.</span></>));
  
            this.logOut();
          }
        }).catch((error) => {
          console.error(error);
    
          toast.error(() => (<>{error.code}<span>{error.message}</span></>));
          
          this.logOut();
        });
      }else{
        this.setState({
          signIn: false,
          dataUser: '',
          privilege: '',
          brand: ''
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.signIn !== prevState.signIn){
      if(prevState.signIn !== ''){
        if(this.state.dataUser.email){
          toast.success(() => (<>Sign In Successfully<span>Hi <strong>{this.state.dataUser.email}</strong>!</span></>));
        }else{
          toast.success(() => (<>Sign Out Successfully<span>Bye!</span></>));
        }
      }
    }
  }

  componentWillUnmount(){
    if(this.cancellableSetting){
      this.cancellableSetting.cancel();
    }
    
    if(this.cancellableUser){
      this.cancellableUser.cancel();
    }

    if(this.cancellableCheck){
      this.cancellableCheck.cancel();
    }

    if(this.cancellableBrand){
      this.cancellableBrand.cancel();
    }
    
    if(this.cancellableLogout){
      this.cancellableLogout.cancel();
    }
  }

  logOut(){
    this.setState({ logOutLoading: true });

    this.cancellableLogout = makeCancellablePromise(signOut(auth));

    this.cancellableLogout.promise.then(() => {
      // this.setState({
      //   signIn: false,
      //   dataUser: '',
      //   logOutLoading: false
      // });
      this.setState({ logOutLoading: false });
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  render(){
    if(this.state.signIn === '' || this.state.appConfig === ''){
      return(
        <Loading text="Memeriksa autentikasi..." page />
      );
    }else{
      return(
        <Suspense fallback={
          <Loading text="Memuat halaman..." page />
        }>
          <Routes>
            <Route path="/login" element={
              this.state.signIn ? (<Navigate to="/admin" />) : (<Login appConfig={this.state.appConfig} />)
            } />
            <Route path="/admin/*" element={
              this.state.signIn ? (<Dashboard dataUser={this.state.dataUser} logOut={this.logOut} logOutLoading={this.state.logOutLoading} privilege={this.state.privilege} brand={this.state.brand} appConfig={this.state.appConfig} />) : (<Navigate to="/login" />)
            } />
            <Route path="*" element={
              this.state.signIn ? (<Navigate to="/admin" />) : (<Navigate to="/login" />)
            } />
          </Routes>
        </Suspense>
      );
    }
  }
}

export default App;