import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyA0_SmR4wAAjTahQOxSa9z63HgzgaP6DOY",
    authDomain: "indo-mak.firebaseapp.com",
    // databaseURL: "https://ncig-tradein-default-rtdb.firebaseio.com",
    projectId: "indo-mak",
    storageBucket: "indo-mak.appspot.com",
    messagingSenderId: "578814410811",
    appId: "1:578814410811:web:e6918b3511c0f2c3badc1e",
    measurementId: "G-RXS9080W6J"
});

getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const fn = getFunctions(firebaseApp);

export default firebaseApp;